// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-page-template-page-template-tsx": () => import("/opt/build/repo/src/templates/PageTemplate/PageTemplate.tsx" /* webpackChunkName: "component---src-templates-page-template-page-template-tsx" */),
  "component---src-templates-project-archive-template-project-archive-template-tsx": () => import("/opt/build/repo/src/templates/ProjectArchiveTemplate/ProjectArchiveTemplate.tsx" /* webpackChunkName: "component---src-templates-project-archive-template-project-archive-template-tsx" */),
  "component---src-templates-project-template-project-template-tsx": () => import("/opt/build/repo/src/templates/ProjectTemplate/ProjectTemplate.tsx" /* webpackChunkName: "component---src-templates-project-template-project-template-tsx" */),
  "component---src-templates-project-taxonomy-template-project-taxonomy-template-tsx": () => import("/opt/build/repo/src/templates/ProjectTaxonomyTemplate/ProjectTaxonomyTemplate.tsx" /* webpackChunkName: "component---src-templates-project-taxonomy-template-project-taxonomy-template-tsx" */),
  "component---src-templates-post-archive-template-post-archive-template-tsx": () => import("/opt/build/repo/src/templates/PostArchiveTemplate/PostArchiveTemplate.tsx" /* webpackChunkName: "component---src-templates-post-archive-template-post-archive-template-tsx" */),
  "component---src-pages-404-tsx": () => import("/opt/build/repo/src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-tsx": () => import("/opt/build/repo/src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("/opt/build/repo/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

