import { combineReducers, Reducer } from 'redux';
import coreReducer, { ICoreReducer } from './core/reducers';

interface IAppState {
  core: ICoreReducer;
}

/**
 * Root Reducer
 * @type {Reducer<any>}
 */
export const rootReducer: Reducer<IAppState> = combineReducers<IAppState>({
  core: coreReducer,
});

export default rootReducer;
