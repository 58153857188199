import { applyMiddleware, compose, createStore, Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import rootReducer from './rootReducer';

/**
 * Configure Store for Application
 * @returns {any}
 */
/* istanbul ignore next */
const makeStore = (): Store => {
  let store;

  if (process.env.NODE_ENV === 'production') {
    store = createStore(rootReducer, compose(applyMiddleware(thunk)));
  } else {
    store = createStore(
      rootReducer,
      composeWithDevTools(applyMiddleware(thunk))
    );
  }

  return store;
};

export default makeStore;
