/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import React from 'react';
import { Provider } from 'react-redux';
import createStore from './src/store/createStore';
import fontAwesomeLib from './src/utils/fontAwesome';
import { ParallaxProvider } from 'react-scroll-parallax';
import { CookiesProvider } from 'react-cookie';

fontAwesomeLib.init();

require('flexigridcss/dist/flexigridcss/modifiers.css');
require('./src/assets/styles/styles.scss');

export const wrapRootElement = ({ element }) => {
  return (
    <Provider store={createStore()}>
      <CookiesProvider>
        <ParallaxProvider>
          {element}
        </ParallaxProvider>
      </CookiesProvider>
    </Provider>
  );
};
