import { Action, ActionCreator, AnyAction, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { ICoreReducer } from 'src/store/core/reducers';
import axios from 'src/utils/axios';
import { IAppState, IStateDispatch } from 'src/utils/interfaces';

export const coreActionTypes = {
  GET_SITE_META: 'GET_SITE_META',
  SET_SITE_META: 'SET_SITE_META',
  GET_SITE_META_FAILED: 'GET_SITE_META_FAILED',
  GET_SITE_OPTIONS: 'GET_SITE_OPTIONS',
  SET_SITE_OPTIONS: 'SET_SITE_OPTIONS',
  SET_MENU_STATE: 'SET_MENU_STATE',
  GET_SITE_OPTIONS_FAILED: 'GET_SITE_OPTIONS_FAILED',
};

export const getSiteMeta: ActionCreator<
  ThunkAction<Promise<any>, IAppState, IStateDispatch, AnyAction>
> = () => {
  return (dispatch: Dispatch): Promise<AnyAction> => {
    return axios
      .get(null)
      .then(response => {
        return dispatch(setSiteMeta(response.data));
      })
      .catch(error => {
        return dispatch(getSiteMetaFailed(error));
      });
  };
};

export const setSiteMeta: ActionCreator<Action> = (data: ICoreReducer) => ({
  type: coreActionTypes.SET_SITE_META,
  data,
});

export const setMenuState: ActionCreator<Action> = (data: ICoreReducer) => ({
  type: coreActionTypes.SET_MENU_STATE,
  data,
});

export const getSiteMetaFailed: ActionCreator<Action> = (error: {}) => ({
  type: coreActionTypes.GET_SITE_META_FAILED,
  error,
});

export const getSiteOptions: ActionCreator<
  ThunkAction<Promise<any>, IAppState, IStateDispatch, AnyAction>
> = () => {
  return (dispatch: Dispatch): Promise<AnyAction> => {
    return axios
      .get(`/acf/v2/options`)
      .then(response => {
        return dispatch(setSiteOptions(response.data.acf));
      })
      .catch(error => {
        return dispatch(getSiteOptionsFailed(error));
      });
  };
};

export const setSiteOptions: ActionCreator<Action> = (data: ICoreReducer) => ({
  type: coreActionTypes.SET_SITE_OPTIONS,
  data,
});

export const getSiteOptionsFailed: ActionCreator<Action> = () => ({
  type: coreActionTypes.GET_SITE_OPTIONS_FAILED,
});
